
import React from 'react';


function ProductCard({ image, name, price }) {
    return (
      <div className="flex flex-col items-center p-1 bg-white rounded shadow w-50">
        <img src={image} alt={name} className="w-36 h-36 object-cover rounded" />
        <p className="mt-2 text-xs font-bold text-gray-700">{name}</p>
        <p className="text-xs font-bold text-gray-500 text-left w-full">{price}</p>
  
      </div>
    );
  }
  

export default ProductCard;