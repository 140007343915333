
import React from 'react';


const ProductCard = ({ name, price, url }) => (
    <div className="flex-shrink-0 w-56 border rounded-lg p-4 bg-white shadow-md">
      <img src={url} alt={name} classNaame="w-full h-40 object-cover rounded-md mb-4" />
      <h3 className="text-lg font-semibold mb-2">{name}</h3>
      <p className="text-gray-700">{price}</p>
    </div>
  );
  

export default ProductCard;