import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDarkMode } from '../DarkModeContext';  
import SidebarComponent from '../components/SidebarComponent';
import ChatWindowMain from '../components/ChatWindow';
import Catelog from '../components/Catelog';
import PromptHub from '../components/prompthub';
import CsvEditor from '../components/CSVEditor';
import '../App.css';

const vectorBasedGifUrl = '/under-construction2.gif';

const MainPage = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  const renderPlaceholder = (pageName) => (
    <div className={`flex flex-col items-center justify-center h-full w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <img src={vectorBasedGifUrl} alt="Under Construction" className="w-1/4 h-1/4 mb-4" />
      <h2 className="text-xl font-semibold">{pageName} is under construction 🚧👷‍♂️! Stay tuned for updates 🎉</h2>
    </div>
  );

  return (
    <div className={`flex ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <SidebarComponent handleLogout={handleLogout} />
      <div className={`flex-grow ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
        <Routes>
          <Route path="chatbot" element={<ChatWindowMain />} />
          <Route path="dashboard" element={renderPlaceholder("Dashboard")} />
          <Route path="crm" element={renderPlaceholder("CRM")} />
          <Route path="analytics" element={renderPlaceholder("Analytics")} />
          <Route path="audience" element={renderPlaceholder("Audience")} />
          <Route path="catalog" element={<Catelog />} />
          <Route path="prompthub" element={<PromptHub />} />
          <Route path="taxonomy" element={<CsvEditor />} />
          <Route path="/" element={<Navigate to="chatbot" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainPage;