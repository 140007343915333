import React from 'react';
import StaticSearchBar from './HomePageStaticSearchBar';
import ProductCard from './HomePageProductCard';

function Modal() {
    return (
      <div className="p-4 bg-blue bg-opacity-70 backdrop-blur-md rounded-lg shadow-lg w-96">
        <div className="mb-4">
          <div className="flex  mb-2">
            <div className='flex-grow'>
                 <div className="bg-pink-500 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">B</div>
            </div>
            <div className="ml-2">
              <p className="font-bold">Brand Marketing</p>
              <p className="text-sm text-black-700">I need to find dresses for a sunset Party to put on our newsletter</p>
            </div>
          </div>
          <div className="flex  mb-2">
            <div className='flex-grow'>
  
            <div className="bg-black text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">F</div>
            </div>
  
            <div className="ml-2">
              <p className="font-bold">Fashion AI</p>
              <p className="text-sm text-black-700">
                Certainly! To adjust the selection for a sexier appeal for your sunset party dress, I refined the search criteria to focus on elements that add a touch of allure while maintaining the event's elegant vibe.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 mt-4">
            <ProductCard image="/dress_1.jpg" name="Red Summer Foliage Scarf Maxi Dress" price='$225' />
            <ProductCard image="/dress_2.jpg" name="Off-White Blue Foliage Maxi Dress" price='$225'/>
            <ProductCard image="/dress_3.jpg" name="Sand Macaw Jungle V Neck Midi Dress" price='$225'/>
          </div>
        </div>
        <StaticSearchBar/>
        {/* <div className="flex items-center justify-between mt-4">
          <div className="w-full px-3 py-2 text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline bg-gray-100">
            can you make a bit less formal ?
          </div>
          <button className="ml-2 p-2 text-white bg-blue-500 rounded-full focus:outline-none focus:shadow-outline">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-5.197-5.196M12 3v12m-6 6h12"></path>
            </svg>
          </button>
        </div> */}
      </div>
    );
  }


  export default Modal;
  