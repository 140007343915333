import React from 'react';
import { FaSignOutAlt, FaUsers, FaMoon, FaSun } from 'react-icons/fa';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { LuLayoutDashboard, LuSheet } from 'react-icons/lu';
import { SiSimpleanalytics } from 'react-icons/si';
import { TbTargetArrow } from 'react-icons/tb';
import { GrCatalogOption } from 'react-icons/gr';
import { PiPlugsConnectedBold } from 'react-icons/pi';
import { CiSettings } from 'react-icons/ci';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDarkMode } from '../DarkModeContext';
import Logo from './Logo';
import '../App.css';

const SidebarComponent = ({ handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const handleNavigation = (path) => {
    navigate(`/mainpage/${path}`);
  };

  return (
    <div className={`sidebar sticky top-0 flex flex-col h-screen ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'} shadow-lg z-10 overflow-y-auto`}
      style={{ transform: 'scale(var(--scale-factor, 1))', transformOrigin: 'top left' }}>
      <Logo />

      <button
        onClick={() => handleNavigation('chatbot')}
        className={`flex items-center p-4 mx-4 my-2 text-lg font-normal text-left rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold ${location.pathname === '/chatbot' && (isDarkMode ? 'bg-gray-700' : 'bg-gray-200')}`}
      >
        <IoChatbubbleEllipsesOutline className="mr-2 text-2xl" />
        Chatbot
      </button>

      <button
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full cursor-not-allowed ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}
        disabled
      >
        <LuLayoutDashboard className="mr-2 text-xl" />
        <span className="text-lg">Dashboard</span>
        <span className={`ml-auto text-xs p-0.5 px-1 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>upgrade</span>
      </button>

      <button
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full cursor-not-allowed ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}
        disabled
      >
        <FaUsers className="mr-2 text-xl" />
        <span className="text-lg">CRM</span>
        <span className={`ml-auto text-xs p-0.5 px-1 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>upgrade</span>
      </button>

      <button
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full cursor-not-allowed ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}
        disabled
      >
        <SiSimpleanalytics className="mr-2 text-xl" />
        <span className="text-lg">Analytics</span>
        <span className={`ml-auto text-xs p-0.5 px-1 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>upgrade</span>
      </button>

      <button
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full cursor-not-allowed ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}
        disabled
      >
        <TbTargetArrow className="mr-2 text-xl" />
        <span className="text-lg">Audience</span>
        <span className={`ml-auto text-xs p-0.5 px-1 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>upgrade</span>
      </button>

      <button
        onClick={() => handleNavigation('catalog')}
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold ${location.pathname === '/catalog' && (isDarkMode ? 'bg-gray-700' : 'bg-gray-200')}`}
      >
        <GrCatalogOption className="mr-2 text-xl" />
        <span className="text-lg">Fashion Vision</span>
      </button>

      <button
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full cursor-not-allowed ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}
        disabled
      >
        <PiPlugsConnectedBold className="mr-2 text-xl" />
        <span className="text-lg">Prompt HUB</span>
        <span className={`ml-auto text-xs p-0.5 px-1 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>upgrade</span>
      </button>

      <button
        onClick={() => handleNavigation('taxonomy')}
        className={`flex items-center p-2 mx-4 my-2 text-sm font-normal text-left rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold ${location.pathname === '/taxonomy' && (isDarkMode ? 'bg-gray-700' : 'bg-gray-200')}`}
      >
        <LuSheet className="mr-2 text-xl" />
        <span className="text-lg">Taxonomy</span>
      </button>

      <div className={`mt-auto py-4 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-2`}>
        <button
          onClick={toggleDarkMode}
          className={`flex items-center justify-between w-full px-4 py-1 mb-1 text-sm font-normal text-left ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold`}
        >
          <div className="flex items-center">
            <span className="text-sm font-medium mx-2">
              {isDarkMode ? 'Light Mode' : 'Dark Mode'}
            </span>
          </div>
          {isDarkMode ? (
            <FaSun className="text-xl" />
          ) : (
            <FaMoon className="text-xl" />
          )}
        </button>

        <button
          onClick={() => handleNavigation('settings')}
          className={`flex items-center justify-between w-full px-4 py-1 mb-1 text-sm font-normal text-left ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold ${location.pathname === '/settings' && (isDarkMode ? 'bg-gray-700' : 'bg-gray-200')}`}
        >
          <div className="flex items-center">
            <span className="text-sm font-medium mx-2">Settings</span>
          </div>
          <CiSettings className="text-xl" />
        </button>

        <button
          onClick={handleLogout}
          className={`flex items-center justify-between w-full px-4 py-1 text-sm font-normal text-left ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} focus:outline-none focus:font-semibold`}
        >
          <div className="flex items-center">
            <span className="text-sm font-medium mx-2">Logout</span>
          </div>
          <FaSignOutAlt className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default SidebarComponent;