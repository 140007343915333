import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';


function Header() {
    return (
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Prompt HUB</h2>
        <div className="flex items-center space-x-4">
          <button className="px-4 py-2 bg-gray-100 rounded-full">32 Selected prompt</button>
          <button className="px-4 py-2 bg-gray-100 rounded-full">5 Newer</button>
          <button className="px-4 py-2 bg-gray-100 rounded-full">5 Edited</button>
          <button className="px-4 py-2 bg-gray-100 rounded-full">82 All</button>
          <div className="ml-auto">
            <button className="px-4 py-2 bg-white border rounded-full">Select your prompts here <span className="ml-2">▼</span></button>
          </div>
        </div>
      </div>
    );
  }

 
  
function PromptCard() {
    return (
      <div className="p-7 bg-white rounded-lg shadow-md">
         <div className="flex items-center mb-2">
            <div className="w-10 h-10 bg-gray-200 overflow-hidden flex-shrink-0">
                <img src='/prompt_image.webp' alt="Avatar" className="w-full h-full object-cover" />
            </div>
        </div>
        <div className="flex items-start mb-4">
          <div>
            <h3 className="text-lg ">PROMPT NAME ENTERS HERE</h3>
            <p className="text-sm text-gray-600">
              Lorem ipsum dolor sit amet consectetur. Ornare sed risus risus vitae lacus et.
              Neque massa blandit sagittis sit. Leo neque semper quis et ut et lectus sit.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="flex space-x-2">
            <button className="text-gray-500 hover:text-gray-700"><FaEdit /></button>
            <button className="text-gray-500 hover:text-gray-700"><FaTrash /></button>
          </div>
          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs font-semibold">ACTIVE</span>
        </div>
      </div>
    );
  }
  

function PromptHub() {
  return (
    <div className="max-w-7xl mx-auto p-8">
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <PromptCard />
        <PromptCard />
        <PromptCard />
        <PromptCard />
        <PromptCard />
        <PromptCard />
      </div>
    </div>
  );
}

export default PromptHub;