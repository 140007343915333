import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import 'tailwindcss/tailwind.css';
import { FaPencilAlt } from "react-icons/fa";
import { useDarkMode } from '../DarkModeContext';  // Make sure this path is correct

const API_URL = `${process.env.REACT_APP_CATALOG_API}/api/taxonomies/category/`;

const CsvEditor = () => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [editIdx, setEditIdx] = useState(null);
  const [taxonomies, setTaxonomies] = useState([]);
  const [activeTaxonomy, setActiveTaxonomy] = useState(null);
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const API_TOKEN = `Bearer ${sessionStorage.getItem('authToken')}`;
    console.log(API_TOKEN, "API TOKEN");

    fetch(`${process.env.REACT_APP_CATALOG_API}/api/taxonomies`, {
      headers: {
        'accept': '*/*',
        'Authorization': API_TOKEN,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Taxonomies fetched:', data);
        if (Array.isArray(data)) {
          data.sort((a, b) => a.id - b.id);
          console.log(data);
          setTaxonomies(data);
          if (data.length > 0) {
            fetchCategoryData(data[0]);
          }
        } else {
          console.error('Error: Taxonomies API did not return an array.');
        }
      })
      .catch(error => console.error('Error fetching taxonomies:', error));
  }, []);

  const fetchCategoryData = (category) => {
    const API_TOKEN = `Bearer ${sessionStorage.getItem('authToken')}`;

    fetch(`${API_URL}${category}`, {
      headers: {
        'accept': '*/*',
        'Authorization': API_TOKEN,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Category data fetched:', data);
        data.sort((a, b) => a.id - b.id);

        const filteredHeaders = ['property', 'examples'];
        const filteredData = data.map(item => ({
          property: item.property,
          examples: item.examples,
          id: item.id
        }));
        setHeaders(filteredHeaders);
        setData(filteredData);
      })
      .catch(error => console.error('Error fetching category data:', error));
  };

  const handleCellChange = (rowIndex, columnId, value) => {
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...oldData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const columns = useMemo(
    () =>
      headers.map((header) => {
        if (header === 'examples') {
          return {
            Header: header,
            accessor: header,
            Cell: ({ value, row: { index }, column: { id } }) => (
              <div className="flex items-center">
                {editIdx === index ? (
                  <input
                    className={`border p-1 flex-grow ${
                      isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-black border-gray-300'
                    }`}
                    value={value}
                    onChange={(e) => handleCellChange(index, id, e.target.value)}
                  />
                ) : (
                  <span className="flex-grow">{value}</span>
                )}
                <FaPencilAlt
                  className={`h-5 w-5 cursor-pointer ml-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
                  onClick={() => setEditIdx(editIdx === index ? null : index)}
                />
              </div>
            ),
          };
        }
        return {
          Header: header,
          accessor: header,
        };
      }),
    [headers, editIdx, isDarkMode]
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  const handleSave = () => {
    console.log("Save button clicked", data);
  };

  const handlePublish = () => {
    const updatedData = data.map(row => ({
      property: row.property,
      examples: row.examples,
      id: row.id
    }));
    const API_TOKEN = `Bearer ${sessionStorage.getItem('authToken')}`;

    updatedData.forEach((item) => {
      fetch(`${process.env.REACT_APP_CATALOG_API}/api/taxonomies/${item.id}`, {
        method: 'PATCH',
        headers: {
          'accept': '*/*',
          'Authorization': API_TOKEN,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ examples: item.examples }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Patch response:', data);
        })
        .catch(error => console.error('Error sending PATCH request:', error));
    });
    console.log("Publish button clicked", updatedData);
  };

  return (
    <div className={`flex flex-col justify-center items-center h-screen p-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <div className="w-full max-w-5xl">
        <div className="flex flex-wrap mb-4">
          {Array.isArray(taxonomies) && taxonomies.length > 0 && taxonomies.map((taxonomy, index) => (
            <button
              key={index}
              className={`py-2 px-4 rounded mr-2 mb-2 font-bold ${
                activeTaxonomy === taxonomy 
                  ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                  : (isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-black hover:bg-gray-300')
              }`}
              onClick={() => {
                fetchCategoryData(taxonomy);
                setActiveTaxonomy(taxonomy);
              }}
            >
              {taxonomy}
            </button>
          ))}
        </div>
        <div className={`overflow-y-auto border rounded-lg ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`} style={{ height: '500px' }}>
          <table
            {...getTableProps()}
            className="border-collapse table-auto w-full"
          >
            <thead className={`sticky top-0 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`p-2 text-left border ${
                        isDarkMode ? 'border-gray-700' : 'border-gray-300'
                      }`}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={isDarkMode ? 'even:bg-gray-800' : 'even:bg-gray-100'}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 border ${
                          isDarkMode ? 'border-gray-700' : 'border-gray-300'
                        }`}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSave}
            className={`font-bold py-2 px-4 rounded mr-2 ${
              isDarkMode 
                ? 'bg-green-600 hover:bg-green-700 text-white' 
                : 'bg-green-500 hover:bg-green-700 text-white'
            }`}
          >
            Save
          </button>
          <button
            onClick={handlePublish}
            className={`font-bold py-2 px-4 rounded ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                : 'bg-blue-500 hover:bg-blue-700 text-white'
            }`}
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  );
};

export default CsvEditor;