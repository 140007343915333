import React from 'react';



function Logo() {
    return (
      <div className="flex justify-center items-center w-full mb-4 mt-5">
        <span className="text-2xl font-bold">Fashion</span>
        <span className="text-2xl font-light">.ai</span>
      </div>
    );
  }

export default Logo;