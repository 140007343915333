import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoSendOutline } from 'react-icons/io5';
import { MdOutlineFileUpload } from 'react-icons/md';
import { TfiControlStop } from "react-icons/tfi";
import { FaTimesCircle, FaDollarSign } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import io from 'socket.io-client';
import axios from 'axios';
import ProductCard from '../components/ProductCard';
import LogDisplay from '../components/LogDisplay';
import { useDarkMode } from '../DarkModeContext'; 
import '../App.css';
import { v4 as uuidv4 } from 'uuid';

const INTERNAL_AGENT_SOCKET_URL = process.env.REACT_APP_INTERNAL_AGENT_SOCKET_URL;
const IS_DEVELOPMENT = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT';

const ChatWindowMain = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [agentStatus, setAgentStatus] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const messagesEndRef = useRef(null);
    const socket = useRef(null);
    const [logs, setLogs] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const { isDarkMode } = useDarkMode();
    const [sessionId, setSessionId] = useState(null);


    const suggestedQuestions = [
        "Ask for a daily sales graph from past 3 months",
        "Generate csv with top 100 customers in 2024",
        "A pie chart with sales % from each category",
        "How many products are in stock in my catalog"
    ];

    const onLogReceived = useCallback((logMessage) => {
        console.log("Log message received in ChatWindowMain:", logMessage);
        setLogs(prevLogs => [...prevLogs, logMessage]);
    }, []);


    const generateSessionId = () => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        sessionStorage.setItem('sessionId', newSessionId);
        return newSessionId;
    };

    const getOrCreateSessionId = () => {
        const storedSessionId = sessionStorage.getItem('sessionId');
        if (storedSessionId) {
            setSessionId(storedSessionId);
            return storedSessionId;
        }
        return generateSessionId();
    };


    useEffect(() => {
        const connectSocket = () => {

            
            const currentSessionId = getOrCreateSessionId();

            console.log("Session ID: ", currentSessionId)

            const newSocket = io(INTERNAL_AGENT_SOCKET_URL, {
                transports: ['websocket', 'polling'],
                reconnectionAttempts: 5,
                debug: true,
                query: { sessionId: currentSessionId }
            });

            socket.current = newSocket;

            socket.current.on('connect', () => {
                console.log('Connected to backend');
                setIsConnected(true);
                let userEmail = sessionStorage.getItem('userEmail');
                let promptData = sessionStorage.getItem('promptData');
                socket.current.emit('register', JSON.stringify({ 
                    email: userEmail, 
                    promptData: promptData,
                    sessionId: currentSessionId
                }));
            });

            socket.current.on('receive_message', (message) => {
                console.log("Final Message Received: ", message);
                if (message.data) {
                    let productList = message.product_list || [];
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { 
                            sender: 'AI', 
                            name: 'Fashion Assistant', 
                            text: message.data, 
                            fileName: message.file_name, 
                            product_list: productList,
                            cost: message.cost 
                        },
                    ]);
                }
                setIsDisable(false);
                setAgentStatus('');
            });

            socket.current.on('agent_status', (status) => {
                setAgentStatus(status);
            });

            socket.current.on('logs', onLogReceived);

            socket.current.on('disconnect', () => {
                console.log('Disconnected from backend');
                setIsConnected(false);
                connectSocket();
                // setTimeout(() => connectSocket(currentSessionId), 3000);
            });

            socket.current.on('connect_error', (err) => {
                console.error('Connection error: ', err);
            });
        };

        connectSocket();

        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const createChart = (data, canvasName) => {
        const ctx = document.getElementById(canvasName).getContext('2d');
        new window.Chart(ctx, data);
    };

    const mountChart = async (fileName) => {
        console.log('Mounting chart filename:', fileName);
    
        const canvas = document.createElement('canvas');
        canvas.id = fileName;
        canvas.width = 600;
        canvas.height = 500;
    
        const messageItems = document.querySelectorAll('.message-item');
        if (messageItems.length > 0) {
            const lastMessageItem = messageItems[messageItems.length - 1];
            lastMessageItem.insertAdjacentHTML('beforeend', '<div class="chart-content"></div>');
            const chartContent = lastMessageItem.querySelector('.chart-content');
            chartContent.appendChild(canvas);
        } else {
            console.error('No elements with class "message-item" found.');
            return;
        }
    
        try {
            const response = await axios.get(`${INTERNAL_AGENT_SOCKET_URL}/files/${fileName}`);
            createChart(response.data, fileName);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.text && lastMessage.fileName) {
                if (lastMessage.fileName.toLowerCase().endsWith('.csv')) {
                    fetch(`/files/${lastMessage.fileName}`)
                        .then(response => response.json())
                        .then(data => {
                            mountChart(data);
                        })
                        .catch(error => console.error('Error fetching chart data:', error));
                } else {
                    mountChart(lastMessage.fileName);
                    scrollToBottom();
                }
            }
        }
    }, [messages]);

    const handleInput = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = (event) => {


        if (event) event.preventDefault();
        if (input.trim() || selectedImage) {
            const newMessage = {
                text: input,
                sender: 'user',
                name: 'User',
                image: selectedImage,
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setInput('');
            setSelectedImage(null);
            setIsDisable(true);

            let userEmail = sessionStorage.getItem('userEmail');
            let promptData = sessionStorage.getItem('promptData');
            const currentSessionId = getOrCreateSessionId();

            const messagePayload = JSON.stringify({ 
                text: input.trim(), 
                image: selectedImage, 
                userEmail: userEmail, 
                promptData: promptData,
                catalogToken: sessionStorage.getItem('authToken'),
                sessionId: currentSessionId
            });

            socket.current.emit('chat_message', messagePayload);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleStop = () => {
        const storedSessionId = sessionStorage.getItem('sessionId');

        socket.current.emit('stop_message', {'sessionId': storedSessionId});
        setIsDisable(false); 
        setAgentStatus('');
    };

    const renderCost = (cost) => {
        if (!IS_DEVELOPMENT || !cost) return null;
        
        const formattedCost = parseFloat(cost).toFixed(4);
        const costPercentage = Math.min(parseFloat(cost) * 100, 100);
        
        return (
            <div className="relative w-full h-1 bg-gray-200 rounded mt-2">
                <div 
                    className="absolute top-0 left-0 h-full bg-green-500 rounded"
                    style={{ width: `${costPercentage}%` }}
                />
                <div className="absolute top-2 right-0 flex items-center text-xs text-gray-500">
                    <FaDollarSign className="mr-1" />
                    <span>{formattedCost}</span>
                </div>
            </div>
        );
    };

    const renderMessage = (message, index) => {
        const userInitial = sessionStorage.getItem('userEmail').charAt(0).toUpperCase();
    
        return (
            <div key={index} className="flex items-start mb-4 message-item">
                <div className={`rounded-full w-10 h-10 flex items-center justify-center font-bold mr-4 mt-2 ${message.sender === 'AI' ? 'bg-black text-white' : 'bg-pink-500 text-white'}`}>
                    {message.sender === 'AI' ? 'F' : userInitial}
                </div>
                <div className={`flex-grow max-w-2xl p-4 rounded-lg ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                    {message.text && (
                        <div dangerouslySetInnerHTML={{ __html: message.text.replace(/\n/g, '<br>') }}></div>
                    )}
                    {message.image && (
                        <img src={message.image} alt="User upload" className="mt-4 max-w-full rounded-lg" />
                    )}
                    {message.fileName && message.fileName.endsWith('.csv') && (
                        <a href={`${INTERNAL_AGENT_SOCKET_URL}/files/${message.fileName}`} download className="text-blue-500 underline mt-4 block">
                            Download {message.fileName}
                        </a>
                    )}
                    {message.product_list && (
                        <div className="flex overflow-x-auto space-x-4 mt-4">
                            {message.product_list.map((product, productIndex) => {
                                const data = product[2];
                                return (
                                    <ProductCard
                                        key={productIndex}
                                        name={data.name}
                                        price={data.price}
                                        url={data.image}
                                        isDarkMode={isDarkMode}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {/* {renderCost(message.cost)} */}
                </div>
            </div>
        );
    };

    const handleSuggestedQuestionClick = (question) => {
        setInput(question);
        handleSubmit();
    };

    return (
        <div className={`flex h-screen relative ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
            <div className="flex flex-col h-full w-full items-center">
                <div className="flex-grow overflow-auto p-4 w-full max-w-4xl">
                    <div className="flex items-center justify-end w-full">
                        <div className={`h-4 w-4 rounded-full ${isConnected ? 'bg-green-500' : 'bg-red-500'}`}></div>
                    </div>
                    {messages.length === 0 && (
                        <div className={`text-center mt-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                            <h2 className="text-2xl font-bold mb-4">Start a new chat with your AI.</h2>
                            <p className="mb-6">Here are some suggestions on what you can do:</p>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {suggestedQuestions.map((question, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleSuggestedQuestionClick(question)}
                                        className={`p-4 rounded-lg text-left ${
                                            isDarkMode
                                                ? 'bg-gray-800 hover:bg-gray-700 text-white'
                                                : 'bg-white hover:bg-gray-100 text-gray-800'
                                        } transition-colors duration-200`}
                                    >
                                        {question}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                    {messages.map((message, index) => renderMessage(message, index))}
                    {agentStatus && (
                        <div className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                            {agentStatus}
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className={`w-full max-w-4xl mx-auto border-t ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'}`}>
                    <div className={`flex items-center rounded-md border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-100 border-red-300'} p-2`}>
                        <textarea
                            placeholder="I need to find dresses for sunset parties to put on our newsletter"
                            className={`flex-grow text-md outline-none p-2 resize-none h-10 max-h-40 overflow-auto ${
                                isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : 'bg-gray-100 text-gray-600 placeholder-gray-500'
                            }`}
                            rows="1"
                            value={input}
                            onChange={handleInput}
                            onKeyPress={handleKeyPress}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = Math.min(e.target.scrollHeight, 160) + 'px';
                            }}
                            disabled={isDisable}
                        />
                        {selectedImage && (
                            <div className="relative inline-block mr-2">
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="h-20 w-20 object-cover rounded mr-2"
                                />
                                <button
                                    onClick={() => setSelectedImage(null)}
                                    className={`absolute top-0 right-0 p-1 rounded-full text-red-300 ${
                                        isDarkMode ? 'bg-gray-600' : 'bg-white'
                                    }`}
                                    style={{
                                        transform: 'translate(50%, -50%)',
                                        border: '1px solid red',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <FaTimesCircle />
                                </button>
                            </div>
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            id="imageUpload"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="imageUpload" className="text-orange-500 p-2 ml-3 cursor-pointer">
                            <MdOutlineFileUpload />
                        </label>
                        <button
                            onClick={isDisable ? handleStop : handleSubmit}
                            className={`text-red-500 p-2 ml-3`}
                        >
                            {isDisable ? <TfiControlStop /> : <IoSendOutline />}
                        </button>
                    </div>
                </div>
                <small className={`${isDarkMode ? 'text-gray-400' : 'text-slate-500'} py-2`}>Fashion.ai can make mistakes. Check important info.</small>
            </div>
            
            {IS_DEVELOPMENT && (
                <button
                    onClick={() => setShowLogs(!showLogs)}
                    className={`fixed bottom-4 right-4 ${isDarkMode ? 'bg-blue-700' : 'bg-blue-500'} text-white p-2 rounded-full shadow-lg z-50`}
                >
                    {showLogs ? <FiChevronRight /> : <FiChevronLeft />}
                </button>
            )}

            {IS_DEVELOPMENT && (
                <div 
                    className={`fixed top-0 right-0 h-full ${
                        isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
                    } shadow-lg transition-all duration-300 ease-in-out overflow-y-auto ${
                        showLogs ? 'w-2/5' : 'w-0'
                    }`}
                    style={{ maxWidth: '40%' }}
                >
                    <div className="p-4">
                        <h2 className="text-xl font-bold mb-4">Logs</h2>
                        {logs.map((log, index) => (
                            <LogDisplay key={index} logData={log} isDarkMode={isDarkMode} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatWindowMain;