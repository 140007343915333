import React from 'react';
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/solid'

function StaticSearchBar() {
  return (
    <div className="flex items-center p-3 bg-gradient-to-r from-red-200 via-white to-red-200 rounded-full shadow-md">
      <div className="flex items-center justify-center w-8 h-8 bg-black text-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
      </svg>
      </div>
      <p className="flex-grow px-4 text-sm text-black">can you make a bit less formal ?</p>
      <div className="flex items-center justify-center w-8 h-8 bg-black text-white rounded-full">
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7-7 7M5 12h14"></path>
        </svg>
      </div>
    </div>
  );
}


export default StaticSearchBar;