import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './screens/login';
import MainPage from './screens/mainpage';
import ProtectedRoute from './components/ProtectedRoute';
import { DarkModeProvider, useDarkMode } from './DarkModeContext';
import './App.css'; // Make sure this import is present

const AppContent = () => {
  const { isDarkMode } = useDarkMode();

  const scrollbarStyle = {
    '--scrollbar-track': isDarkMode ? '#2d3748' : '#f1f1f1',
    '--scrollbar-thumb': isDarkMode ? '#4a5568' : '#888',
    '--scrollbar-thumb-hover': isDarkMode ? '#718096' : '#555',
  };

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`} style={scrollbarStyle}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route 
            path="/mainpage/*" 
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            } 
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

function App() {
  return (
    <DarkModeProvider>
      <AppContent />
    </DarkModeProvider>
  );
}

export default App;