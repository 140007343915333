import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';

const ProductDetail = ({ product, onBack, isDarkMode }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFeatureId, setCurrentFeatureId] = useState(null);
  const [comments, setComments] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchProductDetails = async () => {
    const cliente_id = sessionStorage.getItem('customerId');

    try {
      const response = await axios.get(`${process.env.REACT_APP_VISION_API}/get_content_json/`, {
        params: {
          cliente_id: cliente_id,
          product_id: product.productId,
        },
      });

      const feedbacks = await axios.get(`${process.env.REACT_APP_CATALOG_API}/api/feedbacks/product/${product.productId}`, {
        params: {
          productId: product.productId,
        },
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
          'accept': '*/*',
        },
      });
      console.log(response.data);
      setProductDetails(response.data.vision_output);
      setImageUrls(response.data.image_urls || []); // Ensure we set an empty array if image_urls is undefined
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    console.log(userId);
    fetchProductDetails(userId, product.productId);
  }, [product.productId]);

  if (!productDetails) {
    return <div className={isDarkMode ? "text-white" : "text-black"}>Loading...</div>;
  }

  const openModalWithFeatureId = (featureId) => {
    setCurrentFeatureId(featureId);
    setIsOpen(true);
  };

  const sendVote = async (up) => {
    const token = sessionStorage.getItem('authToken');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CATALOG_API}/api/feedbacks/`,
        {
          taxonomyId: 1,
          productId: product.productId,
          visionOutputFeature: currentFeatureId,
          upvote: up,
          downvote: !up,
          comments: comments,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'accept': '*/*',
          },
        }
      );

      console.log(response.data);
      setIsOpen(false);
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
    }
  };

  const renderFeatures = (features) => {
    return features.map((feature, index) => {
      let key = Object.keys(feature)[1] || Object.keys(feature)[0];
      const value = feature[key];
      return (
        <div key={index} className={`p-4 rounded-md inline-block max-w-xs flex items-center justify-between ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
          <div style={{ paddingRight: 10 }}>
            <h4 className={`text-sm font-medium feature-title ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{key}</h4>
            {Array.isArray(value) ? (
              <ul className="text-sm text-pink-500">
                {value.map((val, idx) => (
                  <li key={idx}>{`${idx + 1}. ${val}`}</li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-pink-500 feature-text">{value}</p>
            )}
          </div>
          <div className="flex items-center space-x-1">
            <button className={`w-6 h-6 hover:bg-transparent ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-500 hover:text-gray-900'}`}>
              <ThumbsUpIcon className="h-4 w-4" />
              <span className="sr-only">Upvote</span>
            </button>
            <button
              className={`w-6 h-6 hover:bg-transparent ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-500 hover:text-gray-900'}`}
              onClick={() => openModalWithFeatureId(feature.id)}
            >
              <ThumbsDownIcon className="h-4 w-4" />
              <span className="sr-only">Downvote</span>
            </button>
          </div>
        </div>
      );
    });
  };

  const validImageUrls = imageUrls.filter(url => url && url.trim() !== '');

  return (
    <div className={`p-8 min-h-screen flex ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <div className="w-1/3 sticky top-8 self-start">
        <button className={`mb-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} onClick={onBack}>
          &larr; Back to Catalog
        </button>
        <div className="slider-container mx-auto" style={{ width: '400px', height: '600px' }}>
          {validImageUrls.length > 0 ? (
            validImageUrls.length > 1 ? (
              <Slider {...settings}>
                {validImageUrls.map((url, index) => (
                  <div key={index}>
                    <img src={url} alt={`Product image ${index + 1}`} className="w-full h-full object-contain" />
                  </div>
                ))}
              </Slider>
            ) : (
              <div>
                <img src={validImageUrls[0]} alt="Product image" className="w-full h-full object-contain" />
              </div>
            )
          ) : (
            <div className={`w-full h-full flex items-center justify-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              No images available
            </div>
          )}
        </div>
      </div>
      <div className="w-2/3 pl-8">
        <h2 className="text-2xl font-bold mb-4">Product Details</h2>
        <p className="mb-8">View and manage the details of the selected product.</p>
        <h3 className="text-2xl font-semibold mb-4 product-name">{product.name}</h3>
        {Object.keys(productDetails).map((section, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-lg font-semibold mb-4 feature-type">{section}</h3>
            <div className="grid grid-cols-2 gap-4">
              {renderFeatures(productDetails[section])}
            </div>
          </div>
        ))}
      </div>

      {/* Dialog component */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className={`p-6 rounded shadow-lg w-full max-w-md ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Rate Comment</h3>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Please provide a rating and feedback for this comment.</p>
            </div>
            <div className="">
              <div className="grid gap-2">
                <label htmlFor="feedback" className="text-sm font-medium">Feedback:</label>
                <textarea 
                  id="feedback" 
                  onChange={(e) => setComments(e.target.value)} 
                  placeholder="Share your thoughts..." 
                  className={`w-full p-2 border rounded ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-black border-gray-300'}`}
                ></textarea>
              </div>
            </div>
            <div className="flex justify-end mt-3">
              <button onClick={() => setIsOpen(false)} className={`border px-4 py-2 mr-2 ${isDarkMode ? 'text-gray-300 border-gray-600' : 'text-gray-700 border-gray-300'}`}>Cancel</button>
              <button onClick={() => sendVote(false)} className={`px-4 py-2 ${isDarkMode ? 'bg-blue-600' : 'bg-blue-500'} text-white`}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function ThumbsUpIcon({ filled = false, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={filled ? 'currentColor' : 'none'}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 10v12" />
      <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
    </svg>
  )
}

function ThumbsDownIcon({ filled = false, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={filled ? 'currentColor' : 'none'}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 14V2" />
      <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
    </svg>
  )
}

export default ProductDetail;