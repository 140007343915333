import React, { useState } from 'react';
import { AlertCircle, User, Bot, Hash, Clock, ChevronDown, ChevronUp } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Custom Alert component
const Alert = ({ variant, title, children }) => {
  const bgColor = variant === 'default' ? 'bg-blue-100' : 'bg-red-100';
  const textColor = variant === 'default' ? 'text-blue-800' : 'text-red-800';
  return (
    <div className={`p-4 ${bgColor} ${textColor} rounded-md`}>
      <h3 className="font-bold mb-2">{title}</h3>
      <div>{children}</div>
    </div>
  );
};

const LogDisplay = ({ logData, isDarkMode }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const parsedLog = logData;

  const formatTokens = (tokens) => {
    return tokens.toLocaleString();
  };

  const formatResponse = (response) => {
    if (typeof response === 'object') {
      return JSON.stringify(response, null, 2);
    }
    return response.replace(/\\n/g, '\n');
  };

  const renderResponse = () => {
    const { name, response } = parsedLog;
    const formattedResponse = formatResponse(response);
    
    if (name === "DataAgent") {
      return (
        <div className="mt-4 rounded-lg overflow-hidden">
          <SyntaxHighlighter 
            language="python" 
            style={isDarkMode ? vscDarkPlus : vs}
            customStyle={{
              margin: 0,
              padding: '1rem',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
            }}
          >
            {formattedResponse}
          </SyntaxHighlighter>
        </div>
      );
    } else if (name === "Code Execution Failed" || name === "Code Execution Successful") {
      return (
        <Alert variant={name === "Code Execution Successful" ? "default" : "destructive"} title={name}>
          <pre className="mt-2 whitespace-pre-wrap text-sm">
            {formattedResponse}
          </pre>
        </Alert>
      );
    } else {
      return <pre className={`mt-1 whitespace-pre-wrap ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{formattedResponse}</pre>;
    }
  };

  return (
    <div className={`shadow-lg rounded-lg p-6 mb-6 max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <div className="flex items-center mb-4">
        <AlertCircle className={`${isDarkMode ? 'text-blue-400' : 'text-blue-500'} mr-2`} />
        <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Log Entry</h2>
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <Hash className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2`} />
          <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Session ID:</span>
          <span className={`ml-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{parsedLog.session_id}</span>
        </div>

        <div className="flex items-center">
          <Hash className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2`} />
          <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Message ID:</span>
          <span className={`ml-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{parsedLog.message_id}</span>
        </div>

        <div className="flex items-center">
          <Bot className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2`} />
          <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Agent:</span>
          <span className={`ml-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{parsedLog.name}</span>
        </div>

        <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-4`}>
          <div className="flex items-start mb-2">
            <User className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2 mt-1`} />
            <div>
              <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>User Question:</span>
              <p className={`mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{parsedLog.user_question}</p>
            </div>
          </div>

          <div className="flex items-start mt-4">
            <Bot className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2 mt-1`} />
            <div className="w-full">
              <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Agent Response:</span>
              {renderResponse()}
            </div>
          </div>
        </div>

        <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-4`}>
          <button
            onClick={() => setShowPrompt(!showPrompt)}
            className={`flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500' 
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            }`}
          >
            {showPrompt ? 'Hide Prompt' : 'Show Prompt'}
            {showPrompt ? <ChevronUp className="ml-2" size={16} /> : <ChevronDown className="ml-2" size={16} />}
          </button>
          {showPrompt && (
            <div className={`mt-4 p-4 rounded-md overflow-auto max-h-96 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <ReactMarkdown className={`prose max-w-none ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                {parsedLog.prompt}
              </ReactMarkdown>
            </div>
          )}
        </div>

        <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-4`}>
          <h3 className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Token Usage:</h3>
          <div className="grid grid-cols-2 gap-2">
            <div className={`p-2 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Prompt Tokens:</span>
              <span className={`block font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{formatTokens(parsedLog.prompt_tokens)}</span>
            </div>
            <div className={`p-2 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Completion Tokens:</span>
              <span className={`block font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{formatTokens(parsedLog.completion_tokens)}</span>
            </div>
            <div className={`p-2 rounded col-span-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Total Tokens:</span>
              <span className={`block font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{formatTokens(parsedLog.total_tokens)}</span>
            </div>
          </div>
        </div>

        <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} flex items-center`}>
          <Clock className="mr-1" size={16} />
          <span>Logged at: {new Date().toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default LogDisplay;